import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { style: {"margin-bottom":"8px","margin-top":"10px","text-align":"left","padding-left":"10px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_textarea = _resolveComponent("a-textarea")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_space = _resolveComponent("a-space")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_spin = _resolveComponent("a-spin")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createBlock(_component_Card, { title: "Announcement" }, {
    default: _withCtx(() => [
      _createVNode(_component_a_spin, { spinning: _ctx.isLoading }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_a_form, {
              layout: "vertical",
              model: _ctx.form,
              onFinish: _ctx.handleFinish
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_row, { gutter: 7 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_col, {
                      xs: { span: 24 },
                      md: { span: 12 }
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_form_item, {
                          label: "Title",
                          name: "from"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_input, {
                              type: "text",
                              value: _ctx.form.title,
                              "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.title) = $event)),
                              id: "title",
                              required: ""
                            }, null, 8, ["value"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_col, {
                      xs: { span: 24 },
                      md: { span: 12 }
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_form_item, {
                          label: "Send To",
                          name: "to"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_select, {
                              mode: "multiple",
                              value: _ctx.form.roles,
                              "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.roles) = $event)),
                              id: "role_id",
                              required: "",
                              "show-search": "",
                              filterOption: _ctx.filterOption
                            }, {
                              default: _withCtx(() => [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.roles.data, (item) => {
                                  return (_openBlock(), _createBlock(_component_a_select_option, {
                                    key: item.value
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(item.label), 1)
                                    ]),
                                    _: 2
                                  }, 1024))
                                }), 128))
                              ]),
                              _: 1
                            }, 8, ["value", "filterOption"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_form_item, {
                  label: "Content",
                  name: "job_title"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_textarea, {
                      value: _ctx.form.content,
                      "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.form.content) = $event)),
                      rows: 6,
                      maxlength: "1000",
                      "show-count": ""
                    }, null, 8, ["value"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_row, { style: {"margin-top":"10px","float":"right"} }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_space, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_button, {
                          type: "primary",
                          size: "large",
                          onClick: _ctx.showMessage,
                          disabled: _ctx.isDisabled
                        }, {
                          default: _withCtx(() => _cache[3] || (_cache[3] = [
                            _createTextVNode(" Submit ")
                          ])),
                          _: 1
                        }, 8, ["onClick", "disabled"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["model", "onFinish"])
          ])
        ]),
        _: 1
      }, 8, ["spinning"])
    ]),
    _: 1
  }))
}